import { CheckIcon } from "~/assets/Icons";

const TeamPlanCard = ({
  planIncludes,
  title,
}: {
  planIncludes: string[];
  title: string;
}) => {

  return (
    <div className="flex flex-col items-center bg-white rounded-[10px] border border-slate-200 shadow-[0px_10px_60px_0px_rgba(38,45,118,0.08)] sm:p-[23px] sm:pb-[29px]  p-[30px_14px] relative">
      <div className="h-[170px]">
        <h3 className="text-center font-bold text-[36px] mb-[10px] text-black uppercase">{title}</h3>
        <div className="flex flex-col items-center justify-center items-baseline">
          <h4 className="leading-[20px] text-[17px] w-10/12 text-center mx-auto">
            Dein Team benötigt mehr Funktionen?
          </h4>
          <p className="text-[12px] mt-2 font-light text-center w-10/12 mx-auto">
            Abo auf Ihre Geschäftsanforderungen abstimmen
          </p>
        </div>
      </div>
      <ul className="h-[260px]">
        {planIncludes.map((planIncludes, index) => (
          <li key={`plan-include-${index}`} className="leading-[25px] mb-[12px] flex items-center gap-[12px]">
            <CheckIcon />
            <h5 className="flex-1 inline-block font-light text-neutral-800">{planIncludes}</h5>
          </li>
        ))}
      </ul>
      <hr className="border-[#E9E9E9] h-1 w-full mt-[10px] mb-[20px]" />
      <a href="https://www.robowriter.de/book-a-demo" target="_blank" rel="noopener noreferrer">
        <button
          type="button"
          className="max-w-[200px] justify-center items-center px-3 py-3 border text-base font-semibold rounded-[8px] shadow-sm hover:text-white border-secondary-500 text-secondary hover:bg-secondary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500 transition duration-300 ease-in-out transform hover:scale-105"
        >
          Kostenlos anfragen
        </button>
      </a>
      <p className="text-center text-neutral-400 text-[12px] capitalize mt-[18px]">
        Individuelles Angebot anfordern
      </p>
    </div>
  );
};

export default TeamPlanCard;