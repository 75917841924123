import { deleteDoc, doc, firestore } from "../../config/fbConfig";

export class TemplateManager {
  userId: string;
  templateId: string;

  constructor(userId: string, templateId: string) {
    this.userId = userId;
    this.templateId = templateId;
  }

  // Delete template from Firebase
  async delete(): Promise<void> {
    const templateRef = doc(firestore, `users/${this.userId}/templates/${this.templateId}`);
    await deleteDoc(templateRef);
  }
} 