import PlansSection from "../../components/sections/PlansSection";
import { useState, useEffect } from "react";
import firebase, { auth } from "../../config/fbConfig";
import Container from "../../components/Container";
import NavbarOutSide from "../../layouts/NavbarAuth";
import Footer from "../../layouts/FooterInside";
import { useNavigate, useLocation } from "react-router-dom";
import SpinningLoader from "../../components/loader/spinning_loader";
import { usePostHog } from 'posthog-js/react';
import { logoutUser } from "../../utils/loginUtils";

const UpgradePage = () => {
  const [loading, setLoading] = useState(false);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const posthog = usePostHog();
  // Get discount code from URL if it exists
  const searchParams = new URLSearchParams(location.search);
  const discountCode = searchParams.get('discount');
  const [openItems, setOpenItems] = useState<string[]>([]);

  // Posthog event upgrade_page:pageview
  useEffect(() => {
    posthog.capture('upgrade_page:pageview')
    // Disable the eslint warning here, since we only want to run this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // should only run once

  useEffect(() => {
    const checkSubscription = async () => {
      const uid = auth?.currentUser?.uid;
      if (uid) {
        const userRef = firebase.firestore().collection("users").doc(uid);
        const userDoc = await userRef.get();
        const userData = userDoc.data();

        if (userData?.subscriptionStatus === "active") {
          setHasActiveSubscription(true);
          return;
        }

        const subscriptionsRef = userRef.collection("subscriptions");
        const subscriptionsSnapshot = await subscriptionsRef.get();

        const hasActiveSubscription = subscriptionsSnapshot.docs.some(
          (doc) => doc.data().status === "active"
        );

        setHasActiveSubscription(hasActiveSubscription);
      }
    };
    checkSubscription();
  }, []);

  const upgradeUser = async (priceId: any) => {
    setLoading(true);

    // Posthog event upgrade_page:upgrade_user_function
    posthog.capture('upgrade_page:upgrade_user_function', { price_id: priceId })

    const uid = auth?.currentUser?.uid;
    const urlPrefix = window.location.href.includes("localhost")
      ? "http://localhost:3000"
      : window.location.href.includes("tst-robowriter")
        ? "https://tst-robowriter.web.app/"
        : "https://app.robowriter.de";

    // Create a new Checkout Session for the order
    const checkoutSessionRef = await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .collection("checkout_sessions").doc()

    const checkoutSessionData: {
      price: any;
      allow_promotion_codes: boolean;
      automatic_tax: boolean;
      tax_id_collection: boolean;
      success_url: string;
      cancel_url: string;
      created: firebase.firestore.FieldValue;
      promotion_code?: string | string;
    } = {
      price: priceId,
      allow_promotion_codes: true,
      automatic_tax: true,
      tax_id_collection: true,
      success_url: `${urlPrefix}/success?priceId=${priceId}`,
      cancel_url: `${urlPrefix}`,
      created: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // Add discount code to checkout session if it exists
    if (discountCode) {
      checkoutSessionData.promotion_code = discountCode;
    }

    await checkoutSessionRef.set(checkoutSessionData);

    const unsubscribe = checkoutSessionRef.onSnapshot(async (snap: any) => {
      const { error, url }: any = snap.data();
      if (error) {
        alert(error.message);
      }
      if (url) {
        window.location.href = url;
      }
    });
    setTimeout(() => {
      unsubscribe();
      console.warn("Timeout: checkout session not found.");
      logoutUser(auth, navigate);
      navigate("/404");
    }, 30000);
  };

  const toggleItem = (item: string) => {
    setOpenItems((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
    );
  };

  if (loading === true)
    return (
      <SpinningLoader />
    );

  return (
    <>
      <div>
        <main className="min-h-screen flex flex-col">
          <NavbarOutSide isShadow={false} />
          <section className="flex-1 py-7 flex flex-col justify-center radial-bg">
            <Container>
              {hasActiveSubscription ? (
                <div className="text-center h-[66vh] flex flex-col items-center justify-center">
                  <div className="text-xl font-semibold text-secondary-500 mb-4">
                    Dieses Angebot ist leider abgelaufen.
                  </div>
                  <button
                    onClick={() => navigate('/home')}
                    className="px-4 py-2 bg-secondary-500 text-white rounded-md hover:bg-secondary-600 transition-colors"
                  >
                    Zurück zu den Vorlagen
                  </button>
                </div>
              ) : (
                <PlansSection
                  bgImage={false}
                  CTA={"Jetzt Upgraden"}
                  createUserAndCheckout1={upgradeUser}
                  createUserAndCheckout2={upgradeUser}
                  createUserAndCheckout3={upgradeUser}
                  discount_code={discountCode}
                />
              )}
            </Container>
          </section>

          <section className="flex-1 py-7 flex flex-col justify-center">
            <Container>
              {/* Logos Section */}
              <section>
                <h1 className="mb-[40px] text-[16px] md:text-[20px] w-10/12 text-center md:w-5/12 mx-auto">Mit Unterstützung von</h1>
                <div className="grid grid-cols-2 md:flex md:justify-between items-center w-full gap-8 md:gap-0">
                  <img src="https://cdn.prod.website-files.com/64e303e4bb8618a54254add6/6500320068ffd386eb4bb7df_Universit%C3%A4t_Bonn.svg" alt="" className="w-full md:w-1/5 grayscale p-8 md:p-0"/>
                  <img src="https://cdn.prod.website-files.com/64e303e4bb8618a54254add6/650032011c0b1257a0518f48_google-cloud-for-startups-p-500.webp" alt="" className="w-full md:w-1/5 grayscale p-8 md:p-0" />
                  <img src="https://cdn.prod.website-files.com/64e303e4bb8618a54254add6/650032008adf51c739b43744_MS_Logo-p-500.png" alt="" className="w-full md:w-1/5 grayscale p-8 md:p-0" />
                  <img src="https://cdn.prod.website-files.com/64e303e4bb8618a54254add6/650032007c5d8668c49e2ade_digitalhub_de.webp" alt="" className="w-full md:w-1/5 grayscale p-8 md:p-0" />
                </div>
              </section>

              <section>
                <h1 className="mb-[40px] mt-[50px] md:mt-[150px] text-[24px] font-semibold md:text-[40px] w-full md:7/12 xl:w-5/12 text-center mx-auto"> Häufig gestellte <span className="text-secondary">Fragen</span></h1>
              </section>
              {/* FAQ section */}
              <div className="w-full max-w-screen-md mx-auto">
                {PrigingQuestions.map((item, index) => (
                  <div 
                    key={index} 
                    className="mb-4 border rounded-lg p-6 cursor-pointer bg-white" 
                    onClick={() => toggleItem(`item-${index}`)}
                  >
                    <div className="flex items-center justify-between text-left">
                      <span className="text-[15px] font-medium pr-8">
                        {item.question}
                      </span>
                      <div
                        className={`flex items-center justify-center h-6 w-6 flex-shrink-0`}
                      >
                        <div className="relative w-3 h-3">
                          <div className="absolute top-1/2 left-0 h-[2px] w-full bg-black transform -translate-y-1/2" />
                          <div 
                            className={`absolute top-1/2 left-0 h-[2px] w-full bg-black transform -translate-y-1/2 transition-transform duration-300 ${
                              openItems.includes(`item-${index}`) ? 'rotate-0' : 'rotate-90'
                            }`} 
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={`overflow-hidden transition-all duration-300 ${
                        openItems.includes(`item-${index}`)
                          ? 'max-h-[600px] opacity-100 mt-4'
                          : 'max-h-0 opacity-0'
                      }`}
                    >
                      <div className="text-[14px] text-gray-700">
                        {item.answer}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Container>
          </section>
          <Footer />
        </main>
      </div>
    </>
  );
};

export default UpgradePage;

const PrigingQuestions = [
  {
    question: 'Kann ich mein Abo jederzeit kündigen?',
    answer: 'Ja, Du kannst bis zu einem Tag vor der nächsten Zahlungsperiode kündigen. Während der laufenden Periode behältst Du vollen Zugriff auf alle Funktionen und wirst danach auf die kostenlose Version umgestellt.',
  },
  {
    question: 'Was macht Robowriter speziell für den DACH-Markt so gut?',
    answer: 'Unsere KI wurde gezielt für den deutschsprachigen Raum (DACH-Markt) entwickelt. Das bedeutet, dass unsere Vorlagen und Textvorschläge perfekt auf die Anforderungen und den Sprachgebrauch in Deutschland, Österreich und der Schweiz abgestimmt sind. Dadurch sparst Du Zeit und erhältst präzise Texte, die Deine Zielgruppe ansprechen.',
  },
  {
    question: 'Kann Robowriter auch Texte in anderen Sprachen erstellen?',
    answer: 'Ja, Robowriter unterstützt über 20 Sprachen und liefert in jeder von ihnen exzellente Ergebnisse. Für deutschsprachige Texte haben wir eine spezielle Optimierung vorgenommen, aber auch in Englisch, Französisch und anderen Sprachen arbeiten die KI auf höchstem Niveau.',
  },
  // {
  //   question: 'Was passiert, wenn ich meine monatlichen Texte nicht aufgebraucht habe?',
  //   answer: 'Ungenutzte Texte bleiben bis zu 12 Monate in Deinem Konto und können genutzt werden, solange Du ein aktives Premium-Abo hast. Bei einem Downgrade verfallen sie am Ende der Zahlungsperiode.',
  // },
  {
    question: 'Was passiert, wenn ich meinen Plan wechsle?',
    answer: 'Du kannst jederzeit up- oder downgraden. Beim Upgrade werden die neuen Funktionen sofort freigeschaltet. Ebenso wird Deine bisherige Bezahlung mit dem neuen Preis verrechnet. Beim Downgrade behältst Du bis zum Ende der Zahlungsperiode Zugriff auf Deinen aktuellen Plan, bevor Du heruntergestuft wirst.',
  },
  {
    question: 'Was passiert, wenn ich mein Limit an Texten pro Monat erreiche?',
    answer: 'Du kannst jederzeit upgraden, und Dein bisheriger Beitrag wird angerechnet. So kannst Du risikofrei einen günstigeren Plan ausprobieren und später aufrüsten.',
  },
  {
    question: 'Bietet ihr Rabatte für gemeinnützige Organisationen, Studierende und Bildungseinrichtungen?',
    answer: 'Ja, wir bieten spezielle Rabatte für Non-Profit-Organisationen, Studierende und Bildungseinrichtungen an. Bitte kontaktiere unser Support-Team (kontakt@robowriter.de) für weitere Informationen.',
  },
  {
    question: 'Welche Zahlungsmethoden werden akzeptiert?',
    answer: 'Du kannst bei uns ganz einfach mit Kreditkarte oder PayPal bezahlen. Für Teamlizenzen können wir auch gesonderte Absprachen zu den Zahlungsmethoden treffen.',
  },
];