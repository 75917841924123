import React, { useEffect, useState, useRef } from 'react';
import { StarsStartIcon } from '~/assets/Icons';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../../config/fbConfig';
import { LoaderIcon } from 'lucide-react';
import { getAllUserData, UserData } from '~/utils/userUtils';
import { useShareableData } from "../../providers/ShareableDataProvider";
import { handleButtonClick } from '~/utils/templates/editTemplates';
import useAutosizeTextArea from "~/hooks/useAutosizeTextArea";
import { usePostHog } from 'posthog-js/react';

const DynamicTemplates = () => {
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<UserData | null>(null);
  const navigate = useNavigate();
  const { showOnBoarding, completeOnBoardingStep } = useShareableData();
  const [isTyping, setIsTyping] = useState(false);
  const typingSpeed = 15; // ms per character
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const posthog = usePostHog();

  useAutosizeTextArea(textAreaRef.current, inputText);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (inputText.trim() && !isLoading) {
        handleButtonClick(inputText, setIsLoading, navigate, completeOnBoardingStep, posthog);
      }
    }
  };

  const typeText = (text: string) => {
    if (isTyping) return;
    setIsTyping(true);
    setInputText('');
    
    let currentText = '';
    let currentIndex = 0;

    const typeChar = () => {
      if (currentIndex < text.length) {
        currentText += text[currentIndex];
        setInputText(currentText);
        currentIndex++;
        setTimeout(typeChar, typingSpeed);
      } else {
        setIsTyping(false);
      }
    };

    typeChar();
  };

  /**
   * Load user data on component mount and when auth state changes
   */
  useEffect(() => {
    const loadData = async () => {
      if (auth.currentUser?.uid) {
        const data = await getAllUserData(auth.currentUser.uid);
        setUserData(data);
      }
    };

    loadData();
  }, []);

  return (
    <div className="flex flex-col items-center px-6 xl:px-20 pt-[15px] md:pt-6 lg:pt-8 xl:pt-9 pb-8 xl:pb-7 rounded-[10px] bg-[#f0e9fe]">
      <span className="text-black font-poppins text-[22px] font-medium leading-[33px] tracking-normal">
        Hallo {userData?.userData.firstname ?? ""}!
      </span>
      <h2 className="mb-[22px] text-black font-poppins text-[22px] md:text-[24px] lg:text-[26px] font-semibold leading-[39px] tracking-normal">
        Was möchtest Du erstellen?
      </h2>
      <div className="flex items-center justify-between mb-6 py-[6px] pl-3 md:pl-4 pr-1.5 min-h-[54px] rounded-[5px] bg-white w-full">
        <textarea
          ref={textAreaRef}
          disabled={isLoading}
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Beschreibe Deine Schreibaufgabe"
          rows={1}
          className="flex-1 resize-none overflow-hidden appearance-none border-none outline-none bg-transparent p-0 m-0 focus:ring-0 placeholder:text-[#aaa8bd] placeholder:font-poppins placeholder:text-xs md:placeholder:text-base placeholder:font-normal placeholder:tracking-[0.01em] placeholder:text-left"
        />
        <button
          className="flex items-center justify-center w-[133px] h-[40px] gap-1 px-4 md:px-6 lg:px-8 rounded-[4px] bg-secondary hover:bg-secondary-600 text-white font-poppins text-[14px] font-medium leading-[21px] tracking-normal text-left"
          onClick={() => handleButtonClick(inputText, setIsLoading, navigate, completeOnBoardingStep, posthog)}
          disabled={isLoading || !inputText.trim()}
        >
          {isLoading ? <div className='flex items-center'>
            <LoaderIcon className="animate-spin h-4 text-white mr-[6px]" />
            Analysiere...
          </div> : (
            <>
              <div className="hidden md:block w-[10px] mr-1 h-3"><StarsStartIcon /></div>
              Starten
            </>
          )}
        </button>
      </div>
      <div className="grid sm:grid-cols-2 xl:grid-cols-4 gap-4 w-full">
        {buttonsDescriptions.map((solutionBtn, index) => (
          <button 
            key={index} 
            type="button" 
            className="group px-3 py-[18px] rounded-[5px] bg-white hover:bg-secondary transition-colors duration-100"
            onClick={() => typeText(solutionBtn.prompt)}
          >
            <h3 className="group-hover:text-white text-black mb-[2px] text-base font-poppins font-semibold tracking-normal text-left">
              {solutionBtn.title}
            </h3>
            <span className="group-hover:text-[#e6e9f5] text-black/50 font-poppins text-xs font-medium tracking-normal text-left block">
              {solutionBtn.description}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default DynamicTemplates;

const buttonsDescriptions = [
  { 
    title: "E-Mail-Kampagne",
    description: "für eine Rabatt-Aktion",
    templateId: '49d94edd3b548b9a1872',
    prompt: "Erstelle eine überzeugende E-Mail-Kampagne für eine Rabatt-Aktion, die unsere Kunden zum Kauf motiviert. Die E-Mail soll einen klaren Call-to-Action und zeitlich begrenzte Angebote enthalten."
  },
  { 
    title: "Social-Media-Anzeige",
    description: "für ein neues Produkt",
    templateId: 'H0P3o5WY1xK4HpQW3Zm8',
    prompt: "Entwickle eine aufmerksamkeitsstarke Social-Media-Anzeige für unser neues Produkt. Der Text soll die wichtigsten Vorteile hervorheben und die Zielgruppe emotional ansprechen."
  },
  { 
    title: "Produktbeschreibung",
    description: "für eine Produkteinführung",
    templateId: 'Y0F3o2MY3xA4XpQV7Xx8',
    prompt: "Verfasse eine detaillierte und überzeugende Produktbeschreibung für unsere Produkteinführung. Hebe die einzigartigen Eigenschaften, Vorteile und den Kundennutzen hervor."
  },
  { 
    title: "Blog-Beitrag",
    description: "über Erfolgsgeschichten von Kunden",
    templateId: 'I0P9o7WY3xN2HpQV8Yr4',
    prompt: "Schreibe einen inspirierenden Blog-Beitrag über Kundenerfolgsstories. Der Artikel soll authentische Erfahrungen teilen und zeigen, wie unsere Lösungen echte Probleme gelöst haben."
  }
];
