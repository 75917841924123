import { Link } from "react-router-dom";
import { EmailIcon, MessageCircleIcon, StepsTemplateIcon, TonfallIcon } from "~/assets/Icons";
import { UserGuideStepEnum } from "~/consts";
import { useShareableData } from "~/providers/ShareableDataProvider";


export const OnboardingSteps = () => {
  const { onboardingData } = useShareableData();

  return (
    <div className="px-6 md:px-0 pb-16 md:pb-3">
      <h3 className="text-black my-[25px] md:my-[15px] text-[20px] font-semibold leading-[30px] text-left">
        Onboarding
      </h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 md:gap-[14px] w-full">
        {OnboardingStepsInfo.map((card, index) => {
          const isDone = onboardingData && onboardingData[card.action]?.done;
          return (
            <Link key={index} to={card.url} className="flex w-full">
              <div className="flex flex-col  w-full gap-5 items-start p-4 rounded-lg border border-[#20235633]">
                <div className="w-[38px] h-[38px] rounded-[7px] bg-[#f0e9fe] flex items-center justify-center">
                  {card.icon}
                </div>
                <div className="min-h-[80px] sm:min-h-[120px] lg:min-h-[90px]">
                  <h3 className="text-black text-base lg:text-[13px] xl:text-base font-semibold text-left">
                    {card.title}
                  </h3>
                  <span className="text-[#797979] text-xs font-normal mt-[3px] text-left lg:line-clamp-2 xl:line-clamp-none leading-[18px]">
                    {card.description}
                  </span>
                </div>
                {isDone ? (
                  <div className="relative opacity-70">
                    <div className="absolute -top-2 -left-2 z-10 w-4 h-4 bg-green-500 rounded-full flex items-center justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <span className="block mb-3 text-black text-xs font-semibold text-left border border-neutral-300 p-2 rounded-md">
                      {card.callToAction}
                    </span>
                  </div>
                ) : (
                  <span className="block mb-3 text-black text-xs font-semibold p-2 text-left">
                    {card.callToAction}
                  </span>
                )}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export const OnboardingStepsInfo = [
  {
    title: "Chat testen",
    description: "Generiere einen Text mit unserem Chat und erhalte 3 zusätzliche Texte",
    callToAction: "KI-Chat testen",
    icon: <MessageCircleIcon />,
    action: UserGuideStepEnum.CHAT,
    url: '/chat'
  },
  {
    title: "Text im eigenen Tonfall erstellen",
    description: "Generiere einen Text in Deinem eigenen Tonfall und erhalte 3 zusätzliche Texte",
    callToAction: "Tonfall erstellen",
    icon: <TonfallIcon />,
    action: UserGuideStepEnum.TONE,
    url: '/tonfall'
  },
  {
    title: "Eigene Vorlage erstellen",
    description: "Etwas fehlt? Generiere Deine eigene Vorlage und erhalte 3 zusätzliche Texte",
    callToAction: "Vorlage erstellen",
    icon: <StepsTemplateIcon />,
    action: UserGuideStepEnum.TEMPLATE,
    url: '/home'
  },
  {
    title: "Für E-Mail-Newsletter registrieren",
    description: "Registriere Dich für unseren E-Mail-Newsletter und erhalte 3 zusätzliche Texte",
    callToAction: "Jetzt registrieren",
    icon: <EmailIcon />,
    action: UserGuideStepEnum.NEWSLETTER,
    url: '/settings'
  },
];