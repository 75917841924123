import LayoutInSide from "../../layouts/LayoutInSide";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import SpinningLoader from "../../components/loader/spinning_loader";
import templates from "../../data/templates.json";
import {
  auth
} from "../../config/fbConfig";
import { toast } from "react-toastify";
import { getUserTemplates } from "../../utils/userUtils";
import { usePostHog } from 'posthog-js/react';
import { SingleLineInput, TextArea } from '../../components/inputs/TextInput';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { handleTemplateEdit } from "../../utils/templates/editTemplates";
import { Loader2 as LoaderIcon } from "lucide-react";

const TemplateEditPage = () => {
  const max_input_length = 2000;
  const [loading, setLoading] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(true);
  const [textLength] = useState(0);
  const [filteredTemplates, setFilteredTemplates] = useState(templates);
  const [input, setInput] = useState('');
  const [error] = useState("");
  const navigate = useNavigate();
  const [templateID, setTemplateID] = useState("");
  const posthog = usePostHog();
  const { id } = useParams();
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);

  const handleTextFieldChange = (e: any) => {
    setInput(e.target.value);
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent new line
      handleEdit(e);
    }
    // Shift+Enter will still create a new line
  }

  const loadUserTemplates = async () => {
    setLoadingTemplates(true);
    const userTemplates = await getUserTemplates(auth.currentUser!.uid);
    const templates_out = [...userTemplates, ...templates];
    setFilteredTemplates(templates_out);
    
    const currentTemplate = templates_out.find((template: any) => template.id === id);
    if (!currentTemplate) {
      toast.error("Template nicht gefunden");
      navigate("/dashboard");
      return;
    }
    
    setSelectedTemplate({
      title: currentTemplate.name,
      description: currentTemplate.description
    });
    setTemplateID(currentTemplate.id);
    setLoadingTemplates(false);
    return templates_out;
  }

  useEffect(() => {
    if (selectedTemplate) {
      posthog.capture('template_edit_page:initialize_edit_template', { template: selectedTemplate })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate]);

  const handleEdit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    try {
      // Find the current template object
      const currentTemplate = filteredTemplates.find((item: any) => item.id === templateID);
      
      if (!currentTemplate) {
        throw new Error('Template not found');
      }

      await handleTemplateEdit(
        currentTemplate,
        input,
        setLoading,
        navigate
      );

      // Posthog event template_edit_page:edit_template
      posthog.capture('template_edit_page:edit_template', { template: selectedTemplate });

    } catch (error) {
      console.error('Error editing template:', error);
      toast.error(
        "Beim Bearbeiten der Vorlage ist ein Fehler aufgetreten.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    } finally {
      setLoading(false);
      setLoadingTemplates(false);
    }
  };

  useEffect(() => {
    loadUserTemplates();
  // Only runs once, so no dependencies
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const renderPlaceholders = () => {
    const template = filteredTemplates.find((item: any) => item.id === id);
    if (loadingTemplates || !template) {
      return <SpinningLoader />
    }
    const placeholders = template.placeholders;

    return placeholders.map((placeholder: any, index: any) => {
      const { label, type } = placeholder;
      return (
        <div className="mb-4" key={index}>
          <div className="flex items-center gap-4 mb-3">
            <label className="block text-base mr-auto" htmlFor={label}>
              {label}
            </label>
            {type === "textarea" && (
              <span className="text-sm text-gray-400">
                {textLength}/{max_input_length}
              </span>
            )}
          </div>

          <div>
            {type === "textarea" && (
              <TextArea
                label="textarea"
                className="w-full"
              />
            )}

            {type === "input" && (
              <SingleLineInput
                label="text"
                className="w-full"
              />
            )}
          </div>
          <p className="text-red-500 mt-5">{error}</p>
        </div>
      );
    });
  };

  if (!selectedTemplate) {
    return <SpinningLoader />;
  }

  return (
    <LayoutInSide>
      <div className="flex flex-col min-h-full">
        {/* Page header */}
        <div className="p-4">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <h1 className="text-2xl font-semibold font-Outfit">Vorlage Ändern</h1>
            <p className="md:text-[15px] text-[12px] text-gray-4000">
              Du befindest dich im Vorlagen-Bearbeitungsmodus. Bitte formuliere deine Änderungen im Änderungsfeld.
            </p>
          </div>
        </div>

        {/* Two Column Grid Layout with Cards */}
        <div className="flex-grow p-4">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* First Column - Edit Controls */}
            <div className="col-span-1">
              <Card className="h-full">
                <CardHeader>
                  <CardTitle>Änderungen</CardTitle>
                  <CardDescription>
                    Beschreibe deine gewünschten Änderungen im Textfeld unten.
                  </CardDescription>
                </CardHeader>
                <CardContent className="space-y-6">
                  {/* Changes Input Field */}
                  <div>
                    <textarea
                      value={input}
                      onChange={handleTextFieldChange}
                      onKeyDown={handleKeyPress}
                      placeholder={"Beschreibe deine Änderungen hier.\n\nBeispiele:\n- Füge bitte ein weiteres Feld mit dem Inhalt ... hinzu.\n- Entferne das Feld ...\n- Ändere den Namen zu ...\n - Passe die beschreibung an ..."}
                      className="min-h-[300px] w-full bg-white rounded placeholder:text-gray-500 p-2"
                    />
                  </div>

                  {/* Action Buttons */}
                  <div className="space-y-4">
                    <Button
                      onClick={handleEdit}
                      className="w-full bg-secondary hover:bg-secondary-600 text-white"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <LoaderIcon className="animate-spin h-5 w-5 text-white mr-2" />
                          Wird bearbeitet...
                        </>
                      ) : (
                        "Änderung Durchführen"
                      )}
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </div>

            {/* Second Column - Template Preview */}
            <div className="col-span-2">
              <Card className="h-full">
                <CardHeader>
                  <CardTitle>{selectedTemplate.title}</CardTitle>
                  <CardDescription>{selectedTemplate.description}</CardDescription>
                </CardHeader>
                <CardContent className="space-y-6">
                  {/* Template Fields */}
                  {renderPlaceholders()}
                </CardContent>
              </Card>
            </div>
          </div>
        </div>

        {/* Loading States */}
        {(loading || loadingTemplates) && <SpinningLoader />}
      </div>
    </LayoutInSide>
  );
};

export default TemplateEditPage;
