import { BrowserRouter, Route, Routes } from "react-router-dom";

// Pages
import SignInPage from "./pages/auth/SignInPage";
import SignUpPage from "./pages/auth/SignUpPage";
import SettingsPage from "./pages/dashboard/SettingsPage";
import PersonaPage from "./pages/dashboard/PersonaPage";
import TemplateDetailPage from "./pages/dashboard/TemplateDetailPage";
import DynamicTemplateDetailPage from "./pages/dashboard/DynamicTemplateDetailPage";
import TemplateEditPage from "./pages/dashboard/TemplateEditPage";
import TemplatesPage from "./pages/dashboard/TemplatesPage";
import UpgradePage from "./pages/dashboard/UpgradePage";
import ImprintPage from "./pages/terms/ImprintPage";
import ProtectedRoutes from "./HOC/ProtectedRouter";
import ErrorPage from "./pages/404/ErrorPage";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage";
import ScrollToTop from "./utils/scrollToTop";
import AccountManagementPage from "./pages/dashboard/accountManagementPage";
import VerifyEmailPage from "./pages/auth/VerifyEmailPage";
import ChatPage from "./pages/chat/ChatPage";
import Redirect from "./utils/Redirect";
import CategoryPage from "./pages/dashboard/CategoryPage";
import { ModalProvider } from "./providers/ModalProvider";
import { ShareableDataProvider } from "./providers/ShareableDataProvider";
import RewardPage from "./pages/reward/RewardPage";
import DocumentsPage from "./pages/dashboard/DocumentsPage";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import DocumentEditPage from './pages/dashboard/DocumentEditPage';
import HomePage from "./pages/dashboard/HomePage";

const queryClient = new QueryClient();

const Paths = () => {
  // Protected paths
  const pathsList = [
    { path: "/upgrade", component: <UpgradePage /> },
    { path: "/home", component: <HomePage /> },
    { path: "/templates", component: <TemplatesPage /> },
    { path: "/templates/:id", component: <TemplateDetailPage /> },
    { path: "/templates/list/:id", component: <CategoryPage /> },
    { path: "/templates/DynamischeVorlage", component: <DynamicTemplateDetailPage /> },
    { path: "/edit/:id", component: <TemplateEditPage /> },
    { path: "/welcome", component: <HomePage /> },
    { path: "/success", component: <HomePage /> },
    { path: "/settings", component: <SettingsPage /> },
    { path: "/chat", component: <ChatPage /> },
    { path: "/documents", component: <DocumentsPage /> },
    { path: "/documents/:documentId", component: <DocumentEditPage /> },
    { path: "/tonfall", component: <PersonaPage /> },
    { path: "/reward-center", component: <RewardPage /> },
  ];

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ShareableDataProvider>
          <ModalProvider>
            <ScrollToTop />
            <Routes>
              <Route path="/sign-in" element={<SignInPage />} />
              <Route path="/sign-up" element={<SignUpPage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/imprint" element={<ImprintPage />} />
              <Route path="/account-management" element={<AccountManagementPage />} />
              <Route path="/verify-email" element={<VerifyEmailPage />} />
              <Route path="/" element={<Redirect url={"/home"} />} />
              <Route path="/" element={<ProtectedRoutes />}>
                {pathsList.map((item, index) => (
                  <Route path={`${item.path}`} element={item.component} key={`page-${index}`} />
                ))}
              </Route>
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </ModalProvider>
        </ShareableDataProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default Paths;
