import { useShareableData } from '../../providers/ShareableDataProvider';
import { UserGuideStepEnum } from '../../consts';
import { ActiveOnboardIcon, CloseIcon } from '../../assets/Icons';

type Props = {
    onClose: () => void;
};

interface IStepDataDetails {
    icon: string;
    title: string;
    description: string,
    link: string,
}

const stepsData: Record<UserGuideStepEnum, IStepDataDetails> = {
    [UserGuideStepEnum.CREATE_ACCOUNT]: {
        icon: '/assets/images/onboarding-account.png', title: 'Benutzerkonto erstellen', description: 'Schließe Deine Registrierung ab', link: '/home',
    },
    [UserGuideStepEnum.CHAT]: {
        icon: '/assets/images/onboarding-chat.png', title: 'Chat testen', description: 'Generiere einen Text mit unserem Chat', link: '/chat',
    },
    [UserGuideStepEnum.TONE]: {
        icon: '/assets/images/onboarding-tone.png', title: 'Text im eigenen Tonfall erstellen', description: 'Generiere einen Text in Deinem eigenen Tonfall', link: '/tonfall',
    },
    [UserGuideStepEnum.TEMPLATE]: {
        icon: '/assets/images/onboarding-template.png', title: 'Eigene Vorlage erstellen', description: 'Etwas fehlt? Generiere Deine eigene Vorlage', link: '/home',
    },
    [UserGuideStepEnum.NEWSLETTER]: {
        icon: '/assets/images/onboarding-subscription.png', title: 'Für E-Mail-Newsletter registrieren', description: 'Registriere Dich für unseren E-Mail-Newsletter', link: '/settings',
    }
};

const OnboardingContent: React.FC<Props> = ({ onClose }) => {
    const { onboardingData } = useShareableData();


    const steps = Object.entries(stepsData).map(([key, data]) => ({
        ...data,
        key,
        active: onboardingData?.[key as UserGuideStepEnum]?.done ?? false
    }));

    const completedSteps = steps.filter(step => step.active).length;

    return (
        <div className="flex flex-col md:flex-row h-full md:h-auto overflow-y-auto md:overflow-y-visible">
            <img src="/assets/images/logo-white.svg" alt="icon" className="absolute top-4 left-3 invert brightness-0 h-5 md:hidden flex" />
            <button
                id="closeModal"
                onClick={onClose}
                className="absolute right-5 top-2 md:right-4 md:top-4 m-0 md:mb-4 md:w-6 md:h-6 w-3 h-3"
            >
                <CloseIcon />
            </button>

            <div className="bg-onboarding-gradient w-full md:w-5/12 max-h-[150px] md:max-h-none p-[24px] flex flex-col items-center md:items-start justify-between">
                <img src="/assets/images/logo-white.svg" alt="icon" className="h-7 invert brightness-0 hidden md:flex" />
                <div className="flex flex-col justify-center items-center mb-8">
                    <div className="text-center text-white">
                        <div className="flex items-center justify-center mb-2">
                            <img src="/assets/images/onboarding-illustration.png" alt="icon" className="md:w-44 md:h-44 h-14 w-14" />
                        </div>
                        <p className="font-bold md:text-xl text-xs md:mt-4 font-semibold">Erhalte mehr Texte</p>
                        <p className="md:text-sm text-[8px] mt-0  md:mt-2.5 w-10/12 mx-auto m-0 md:mb-[100px]">Einführung abschließen und noch mehr Texte generieren</p>
                    </div>
                </div>
            </div>

            <div className="pt-3.5 md:pt-[34px] px-3.5 md:pl-6 md:pr-10 pb-3.5 md:pb-8 flex-1 md:w-9/12 w-full">
                <h1 className="text-base md:text-[21px] font-bold text-left">Erste Schritte mit Robowriter</h1>
                <p className="text-gray-500 text-xs md:text-[13px] leading-5 my-1 md:my-2 text-start">
                    Du erhältst einmalig bis zu 12 Bonus-Texte, wenn du die Einführung vollständig abschließt
                </p>

                <div className="flex h-3 mt-2 md:mt-1 justify-between md:pr-10">
                    {steps.map((_, index) => {
                        const baseClass = "w-12 sm:w-15 md:w-11 h-[2px] md:h-1";
                        const activeClass = index < completedSteps ? "bg-black" : "bg-gray-300";
                        const roundedClass = index === 0 ? "rounded-l-[60px]" : (index === steps.length - 1 ? "rounded-r-[60px]" : "");

                        return (
                            <div key={index} className={`${baseClass} ${activeClass} ${roundedClass}`}></div>
                        );
                    })}
                </div>

                <div className="space-y-3 w-full mt-2">
                    {steps.map((step, index) => (
                        <a key={index} href={step.link} className="block">
                            <div className={`flex items-center justify-between py-2 px-4 border rounded-lg ${step.active ? 'border-gray-300' : 'border-gray-300'}`}>
                                <div className="flex items-center">
                                    <img src={step.icon} alt="icon" className="h-5" />
                                    <div className="ml-4">
                                        <div className="text-[11px] font-semibold text-left">{step.title}</div>
                                        <div className="text-gray-500 text-[10px] mt-0.5 text-left hidden md:flex">{step.description}</div>
                                    </div>
                                </div>
                                {step.active ? (
                                    <ActiveOnboardIcon />
                                ) : (
                                    <div className="rounded-full min-w-[17px] w-[17px] h-[17px] border border-gray-400/37" />
                                )}
                            </div>
                        </a>
                    ))}
                </div>

                <button
                    onClick={onClose}
                    data-testid="welcome-close-button"
                    className="ml-auto w-fit btn-default mt-8 md:mt-8 h-8 md:h-10 text-center items-center text-xs md:text-sm flex rounded font-semibold text-secondary bg-transparent tracking-wider border-[1px] border-secondary transition-all duration-200 ease-in-out hover:bg-secondary hover:text-white"
                >
                    <span className="mx-8 transition-colors text-center w-full nowrap duration-200">Schließen</span>
                </button>
            </div>
        </div>
    );
};

export default OnboardingContent;
