import Container from "../components/Container";
import Sidebar from "./Sidebar";
import FooterInside from "./FooterInside";
import { UserData, getAllUserData } from "../utils/userUtils";
import React, { useState, useEffect } from 'react';
import {
  firestore,
  collection,
  doc,
  onSnapshot,
  auth,
  query,
  where
} from "../config/fbConfig";
import { Crisp } from "crisp-sdk-web";
import SpinningLoader from '../components/loader/spinning_loader';
import UpgradeBanner from "../components/header/UpgradeBanner";
import NavbarInSide from "./NavbarInSide";

type Props = {
  children: React.ReactNode;
  onSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hasNoMt?: boolean;
  paddingZero?: boolean;
};

const LayoutInSide = (props: Props) => {

  // State Variables
  const [openSidebar, setSidebarState] = useState(false);
  const [userData, setUserData] = useState<UserData | null>(null);

  /**
   * Load user data on component mount and when auth state changes
   */
  useEffect(() => {
    const loadData = async () => {
      if (auth.currentUser?.uid) {
        const data = await getAllUserData(auth.currentUser.uid);
        setUserData(data);
      }
    };

    loadData();
  }, []);

  /**
   * This useEffect hook is responsible for subscribing to the user's document in Firestore.
   * If the user is authenticated and their document exists, it sets the local user data state.
   * If the user's document does not exist, it logs a message.
   * If the user is not authenticated, it logs a message.
   * It also cleans up the subscription when the component unmounts.
  */
  useEffect(() => {
    let unsubscribe: () => void;

    if (auth.currentUser?.uid) {
      const userDocRef = doc(collection(firestore, "users"), auth.currentUser.uid);

      // First subscription for user data
      unsubscribe = onSnapshot(userDocRef, (userDocSnap: any) => {
        if (userDocSnap.exists()) {
          const userDocData = userDocSnap.data();
          setUserData(prevData => ({
            userData: userDocData,
            productData: prevData?.productData || null
          }));
        } else {
          // Handle the case when the document does not exist
          console.log('User document does not exist.');
        }
      });
    } else {
      // Handle the case when auth.uid is falsy (user is not authenticated)
      console.log('User is not authenticated.');
    }

    return () => {
      if (unsubscribe) {
        unsubscribe(); // Clean up subscription on unmount
      }
    }
  }, []);

  // Initialize Crisp chat when the user is authenticated
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(function (user) {
      if (user && userData && userData.userData) {
        // Check if user is in paid tier
        // Initialize Crisp chat
        Crisp.configure("8e95355d-1778-4151-9a31-b75b01e0cc89", {
          // Options ref: https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/npm/#available-methods
          // Set Autoload to false, so that it only loads when the user is authenticated
          autoload: false,
          locale: 'de'
        });

        // Set user data for Crisp chat
        // Show the chat only for paid users
        if (!userData.userData.email || userData.userData.freetrial === true) {
          // If the data is not loaded yet, return.
          // Otherwise errors will pop up in the console.
          return;
        }
        
        Crisp.user.setEmail(userData.userData.email);
        Crisp.user.setNickname(userData.userData.fullname);
        Crisp.setTokenId(user.uid);
        Crisp.load();
      }
    });

    return () => unsubscribe();  // Cleanup subscription on unmount
  }, [userData]);  // Add userData as dependency

  /**
   * This useEffect hook enables toggling the sidebar by clicking outside of it.
   */
  useEffect(() => {
    openSidebar ? document.body.classList.add("show-mobile-sidebar") : document.body.classList.remove("show-mobile-sidebar");
  }, [openSidebar]);

  // Function to check if the footer should be displayed
  const shouldShowFooter = () => {
    const path = window.location.pathname;
    return path.endsWith("/templates") || path.endsWith("/home") || path.endsWith("/settings");
  };

  // Effect to handle Crisp chat visibility based on pathname
  useEffect(() => {
    if (typeof $crisp !== 'undefined') {
      if (window.location.pathname.startsWith('/chat')) {
        $crisp.push(["do", "chat:hide"]);
      } else {
        $crisp.push(["do", "chat:show"]);
      }
    }
  }, []);

  return (
    <>
      <section className="min-h-screen flex flex-col md:flex-row bg-light">
        {/* Only render sidebar when userData is available */}
        {userData && (
          <div className="hidden md:block">
            <Sidebar userData={userData} />
          </div>
        )}
        {openSidebar && <div className="fixed inset-0 z-10 bg-gray-600 opacity-50" onClick={() => setSidebarState(false)} />}
        <div className="flex-1 flex flex-col">
          {userData && (
            <>
              <NavbarInSide onSearch={props.onSearch} userData={userData} setSidebarState={setSidebarState} />
              <UpgradeBanner userData={userData} />
            </>
          )}
          <Container>
            {/* Only render mobile sidebar when userData is available */}
            {userData && (
              <>
                <div className="md:hidden">
                  <Sidebar userData={userData} />
                </div>
              </>
            )}
            {!userData ? (
              <div className="flex items-center justify-center h-[calc(100vh-100px)]">
                <SpinningLoader />
              </div>
            ) : (
              props.children
            )}
          </Container>
          {shouldShowFooter() && <FooterInside />}
        </div>
      </section>
    </>
  );
};

export default LayoutInSide;
