import React, { useEffect, useState } from 'react';
import { Input } from '../ui/input';
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '../ui/table';
import { Button } from '../ui/button';
import { MoreHorizontal } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { DocumentManager, DocumentData } from '../../utils/documents/DocumentManager';
import { Star, Edit2, Copy, Trash2 } from 'lucide-react';
import { auth, firestore } from '../../config/fbConfig';
import { formatDistanceToNow } from 'date-fns';
import { de } from 'date-fns/locale';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../ui/alert-dialog";
import { useManageStripeSubscription } from "../../utils/ManageStripeSubscription";
import { doc, getDoc } from "firebase/firestore";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../ui/dialog";
import { Link } from 'react-router-dom';

const RecentDocuments = () => {
  const [documents, setDocuments] = useState<DocumentData[]>([]);
  const navigate = useNavigate();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState<string | null>(null);
  const manageStripeSubscription = useManageStripeSubscription();
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [documentToRename, setDocumentToRename] = useState<string | null>(null);
  const [newTitle, setNewTitle] = useState('');

  const documentsToDisplay = isFreeTrial && documents.length > 3 ? documents.slice(0, 3) : documents.slice(0, 5);

  useEffect(() => {
    const fetchDocuments = async () => {
      if (auth.currentUser) {
        const userId = auth.currentUser.uid;
        const docs = await DocumentManager.getAllForUser(userId);
        setDocuments(docs.map(docManager => docManager.toJSON()));
      } else {
        navigate('/sign-in');
      }
    };
    fetchDocuments();
  }, [navigate]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (auth.currentUser) {
        const userDoc = await getDoc(doc(firestore, "users", auth.currentUser.uid));
        if (userDoc.exists()) {
          setIsFreeTrial(userDoc.data().freetrial === true);
        }
      }
    };

    fetchUserData();
  }, []);

  const handleStatusChange = async (docId: string, newStatus: string) => {
    try {
      const doc = await DocumentManager.loadById(auth.currentUser!.uid, docId);
      if (doc) {
        doc.setStatus(newStatus as DocumentData['status']);
        await doc.save();
        const updatedDocs = await DocumentManager.getAllForUser(auth.currentUser!.uid);
        setDocuments(updatedDocs.map(doc => doc.toJSON()));
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleDelete = async () => {
    if (!documentToDelete) return;
    try {
      const doc = await DocumentManager.loadById(auth.currentUser!.uid, documentToDelete);
      if (doc) {
        await doc.delete();
        setDocuments(prev => prev.filter(d => d.id !== documentToDelete));
      }
    } catch (error) {
      console.error('Error deleting document:', error);
    }
    setDocumentToDelete(null);
    setIsDeleteDialogOpen(false);
  };

  const handleFavorite = async (docId: string, currentFavorite: boolean) => {
    try {
      const doc = await DocumentManager.loadById(auth.currentUser!.uid, docId);
      if (doc) {
        doc.setFavorite(!currentFavorite);
        await doc.save();
        const updatedDocs = await DocumentManager.getAllForUser(auth.currentUser!.uid);
        setDocuments(updatedDocs.map(doc => doc.toJSON()));
      }
    } catch (error) {
      console.error('Error updating favorite status:', error);
    }
  };

  const handleDuplicate = async (docId: string) => {
    try {
      const doc = await DocumentManager.loadById(auth.currentUser!.uid, docId);
      if (doc) {
        await doc.duplicate();
        const updatedDocs = await DocumentManager.getAllForUser(auth.currentUser!.uid);
        setDocuments(updatedDocs.map(doc => doc.toJSON()));
      }
    } catch (error) {
      console.error('Error duplicating document:', error);
    }
  };

  const handleRename = async () => {
    if (!documentToRename || !newTitle.trim()) return;

    try {
      const doc = await DocumentManager.loadById(auth.currentUser!.uid, documentToRename);
      if (doc) {
        doc.setTitle(newTitle.trim());
        await doc.save();
        const updatedDocs = await DocumentManager.getAllForUser(auth.currentUser!.uid);
        setDocuments(updatedDocs.map(doc => doc.toJSON()));
      }
    } catch (error) {
      console.error('Error renaming document:', error);
    }
    setIsRenameDialogOpen(false);
    setTimeout(() => {
      document.body.style.pointerEvents = ''
    }, 100)
    setDocumentToRename(null);
    setNewTitle('');
  };

  const renderDocumentTitle = (doc: DocumentData) => (
    <span>
      {doc.isFavorite && '⭐ '}
      {doc.title}
    </span>
  );

  if (documents.length === 0) {
    return (
      <div className="flex flex-col px-6 md:px-0">
        <div className="my-[25px] md:my-[15px]">
          <div className="flex items-center justify-between">
            <h1 className="text-[20px] text-black font-semibold">Verlauf</h1>
          </div>
        </div>
        
        <div className="border border-gray-200 rounded-lg p-8 bg-white">
          <div className="flex flex-col items-center text-center max-w-md mx-auto gap-4">

            <h3 className="text-lg font-medium text-gray-900">
              Dein Dokumenten-Verlauf ist noch leer
            </h3>
            <p className="text-sm text-gray-500">
              Hier werden deine kürzlich erstellten und bearbeiteten Dokumente angezeigt. Erstelle jetzt dein erstes Dokument!
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col px-6 md:px-0">
        {/* Page header */}
        <div className="my-[25px] md:my-[15px]">
          <div className="flex items-center justify-between">
            <h1 className="text-[20px] text-black font-semibold">Verlauf</h1>
            <Link to={'/documents'} className='bg-transparent text-black font-medium text-sm'>Alles anzeigen</Link>

          </div>
        </div>

        {/* Table section */}
        <div className="relative">
          <div className="relative border border-gray-200 rounded-lg overflow-hidden">
            <Table>
              <TableHeader className="bg-[#F9F6FE]">
                <TableRow>
                  <TableHead className="font-medium">Name</TableHead>
                  {/* Hide these columns on mobile */}
                  <TableHead className="hidden lg:table-cell font-medium">Beschreibung</TableHead>
                  <TableHead className="table-cell font-medium">Zuletzt bearbeitet</TableHead>
                  <TableHead className="hidden lg:table-cell font-medium">Status</TableHead>
                  <TableHead className="font-medium"></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody className="bg-white">
                {documentsToDisplay.map((doc, index) => (
                  <React.Fragment key={doc.id}>
                    <TableRow
                    >
                      <TableCell>
                        <button
                          onClick={() => navigate(`/documents/${doc.id}`)}
                          className="text-left hover:text-purple-600 hover:underline"
                          disabled={isFreeTrial && index >= 3}
                        >
                          {renderDocumentTitle(doc)}
                        </button>
                      </TableCell>
                      {/* Hide these cells on mobile */}
                      <TableCell className="hidden md:table-cell">
                        {DocumentManager.decodeAndCleanHtml(doc.richText)}
                      </TableCell>
                      <TableCell className="table-cell">
                        {formatDistanceToNow(doc.updatedAt, { addSuffix: true, locale: de })}
                      </TableCell>
                      <TableCell className="hidden md:table-cell">
                        <Select
                          defaultValue={doc.status}
                          onValueChange={(value) => handleStatusChange(doc.id, value)}
                        >
                          <SelectTrigger className="w-[140px] bg-white flex justify-between items-center">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent className="bg-white">
                            <SelectItem
                              value="Entwurf"
                              className="hover:bg-purple-50 cursor-pointer"
                            >
                              Entwurf
                            </SelectItem>
                            <SelectItem
                              value="Fertig"
                              className="hover:bg-purple-50 cursor-pointer"
                            >
                              Fertig
                            </SelectItem>
                            <SelectItem
                              value="Vorlage"
                              className="hover:bg-purple-50 cursor-pointer"
                            >
                              Vorlage
                            </SelectItem>
                          </SelectContent>
                        </Select>
                      </TableCell>
                      <TableCell>
                        <DropdownMenu>
                          <DropdownMenuTrigger className="hover:bg-gray-100 p-2 rounded-md bg-[#F7F7F7]">
                            <MoreHorizontal className="h-4 w-4" />
                          </DropdownMenuTrigger>
                          <DropdownMenuContent className="bg-white text-neutral-500" align="end">
                            <DropdownMenuItem
                              className="hover:bg-purple-50 cursor-pointer flex items-center"
                              onClick={() => handleFavorite(doc.id, doc.isFavorite || false)}
                            >
                              <Star className="h-4 text-neutral-400" />
                              {doc.isFavorite ? 'Unfavorisieren' : 'Favorisieren'}
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              className="hover:bg-purple-50 cursor-pointer flex items-center"
                              onClick={() => {
                                setDocumentToRename(doc.id);
                                setNewTitle(doc.title);
                                setIsRenameDialogOpen(true);
                              }}
                            >
                              <Edit2 className="h-4 text-neutral-400" />
                              Umbenennen
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              className="hover:bg-purple-50 cursor-pointer flex items-center"
                              onClick={() => handleDuplicate(doc.id)}
                            >
                              <Copy className="h-4  text-neutral-400" />
                              Duplizieren
                            </DropdownMenuItem>
                            <DropdownMenuSeparator className='bg-neutral-200/80' />
                            <DropdownMenuItem
                              className="text-red-500 hover:bg-red-50 cursor-pointer flex items-center"
                              onClick={() => {
                                setDocumentToDelete(doc.id);
                                setIsDeleteDialogOpen(true);
                              }}
                            >
                              <Trash2 className="h-4 text-red-500" />
                              Löschen
                            </DropdownMenuItem>
                          </DropdownMenuContent>

                        </DropdownMenu>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
                {isFreeTrial && documents.length > 3 && (
                  <>
                    <tr className='absolute bottom-2.5 left-1/2 transform -translate-x-1/2 flex gap-2.5 w-11/12 sm:w-10/12 md:max-w-[520px]'>
                      <img
                        src="/assets/images/padlock.png"
                        alt="Locked"
                        className="w-11 h-11"
                      />
                      <div className='flex flex-col gap-2 md:max-w-[500px]'>
                        <span className="text-[16px] font-semibold leading-[19px]">
                          Ausgeblendete Texte in Deinem Verlauf freischalten
                        </span>
                        <span className="text-[12px] leading-[22px]">Sobald Dein Verlauf drei Einträge überschreitet, wird der Rest ausgeblendet.
                          Verwende unsere Premium-Version, um unbegrenzten Zugang zu erhalten.</span>
                        <Button
                          onClick={() => manageStripeSubscription()}
                          className="bg-white hover:bg-white border border-neutral-400 text-neutral-600 max-w-[110px]"
                        >
                          Upgrade
                        </Button>
                      </div>
                    </tr>
                    {[...Array(2)].map((_, i) => (
                      <TableRow key={`row-${i}`} className="blur webkit-10 border-0 pointer-events-none text-neutral-500 h-[120px] sm:h-[100px] md:h-[78px]">
                        <TableCell>Beispieldokument</TableCell>
                        <TableCell className="hidden md:table-cell">Beschreibung ausgeblendet...</TableCell>
                        <TableCell className="table-cell">Vor kurzer Zeit</TableCell>
                        <TableCell className="hidden md:table-cell">Entwurf</TableCell>
                        <TableCell>
                          <DropdownMenu>
                            <DropdownMenuTrigger className="hover:bg-gray-100 p-2 rounded-md bg-[#F7F7F7]">
                              <MoreHorizontal className="h-4 w-4" />
                            </DropdownMenuTrigger>
                          </DropdownMenu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>

      {/* Delete Dialog */}
      {isDeleteDialogOpen && (
        <AlertDialog open={isDeleteDialogOpen} onOpenChange={(open) => {
          setIsDeleteDialogOpen(open);
          setTimeout(() => {
            if (!open) {
              document.body.style.pointerEvents = '';
            }
          }, 100);
        }}>
          <AlertDialogContent className="bg-white">
            <AlertDialogHeader>
              <AlertDialogTitle>Bist Du sicher?</AlertDialogTitle>
              <AlertDialogDescription>
                Diese Aktion kann nicht rückgängig gemacht werden. Dies wird dein Dokument dauerhaft löschen.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={() => setIsDeleteDialogOpen(false)}>Abbrechen</AlertDialogCancel>
              <AlertDialogAction
                onClick={handleDelete}
                className="bg-red-600 hover:bg-red-700"
              >
                Löschen
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}

      {/* Rename Dialog */}
      {isRenameDialogOpen && (
        <Dialog open={isRenameDialogOpen} onOpenChange={(open) => {
          setIsRenameDialogOpen(open);
          setTimeout(() => {
            if (!open) {
              document.body.style.pointerEvents = '';
            }
          }, 100);
        }}>
          <DialogContent className="bg-white">
            <DialogHeader>
              <DialogTitle>Dokument umbenennen</DialogTitle>
            </DialogHeader>
            <div className="py-4">
              <Input
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
                placeholder="Neuer Titel"
                className="w-full"
              />
            </div>
            <DialogFooter>
              <Button variant="outline" onClick={() => setIsRenameDialogOpen(false)}>
                Abbrechen
              </Button>
              <Button
                onClick={handleRename}
                className="bg-secondary hover:bg-secondary-600 text-white"
              >
                Umbenennen
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default RecentDocuments;
