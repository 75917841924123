import React, { ReactNode, createContext, useContext, useState } from 'react'
import OnboardingModal from '../layouts/Modal/OnboardingModal';
import { useNavigate } from 'react-router-dom';
import { ModalTypeEnum } from '../consts';
import InviteUserModal from '../../src/layouts/Modal/InviteUserModal';
import ReviewModal from '../../src/layouts/Modal/ReviewModal';
import { auth } from "../config/fbConfig";
import { usePostHog } from 'posthog-js/react';

interface IModalContext {
    setActiveModal: React.Dispatch<React.SetStateAction<ModalTypeEnum | null>>
}

const ModalContext = createContext<IModalContext>({
    setActiveModal: () => { }
})

interface IModalProps {
    onClose: () => void;
}

const ModalMap = {
    [ModalTypeEnum.OnboardingModal]: {
        Component: OnboardingModal
    },
    [ModalTypeEnum.InviteUserModal]: {
        Component: InviteUserModal
    },
    [ModalTypeEnum.TestimonialModal]: {
        Component: (props: IModalProps) => <ReviewModal {...props} isTestimonial/>
    },
    [ModalTypeEnum.FeedbackModal]: {
        Component: (props: IModalProps) => <ReviewModal {...props}/>
    }
}

interface ModalProviderProps {
    children: ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
    const [activeModal, setActiveModal] = useState<ModalTypeEnum | null>(null);
    const isFromVerifyPage = window.location.pathname === "/welcome";
    const posthog = usePostHog();
    const navigate = useNavigate();

    const onClose = () => {
        if (isFromVerifyPage) {
            navigate("/home");
        }

        if (activeModal === ModalTypeEnum.OnboardingModal) {
            // Facebook Pixel Lead event tracking
            if (typeof window !== 'undefined' && (window as any).fbq) {
                (window as any).fbq('track', 'Lead');
            }

            // Google Enhanced Conversion Tracking Code
            // Needs to fire before the actual event code, otherwise the attribute is not set
            // ref: https://support.google.com/google-ads/answer/13262500
            if (typeof window !== 'undefined' && (window as any).dataLayer) {
                (window as any).dataLayer.push({
                    'email_address': auth.currentUser?.email,
                });

                (window as any).dataLayer.push({
                    'event': 'SignUp',
                });
            } else {
                posthog.capture('conversion_tracking:dataLayer_not_found');
            }

            // Bing UET tag for conversion tracking
            if (typeof window !== 'undefined' && (window as any).uetq) {
                (window as any).uetq.push(
                    'event', 'SignUp', {
                });
            }

            // Posthog event sign_up:completed
            posthog.capture('onboarding_modal:closed');
        }

        setActiveModal(null)
    }

    const { Component } = activeModal ? ModalMap[activeModal] : { Component: () => null };

    return (
        <ModalContext.Provider value={{ setActiveModal }}>
            <>
                {activeModal && <Component onClose={onClose} />}
                {children}
            </>
        </ModalContext.Provider>
    )
}

export const useModal = () => useContext(ModalContext);