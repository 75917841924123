import {
  Auth,
  AuthProvider,
  signInWithPopup,
} from "firebase/auth";

import firebase, { auth } from "../config/fbConfig";
import { PostHog } from "posthog-js/react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../config/fbConfig";

// Crisp Chat
import { Crisp } from "crisp-sdk-web";

export function handleGoogleSignUp(auth: Auth, provider: AuthProvider, navigate: (arg0: string) => void, httpsCallable: (arg0: any, arg1: string) => any, functions: any, newsletter_accepted: Boolean, posthog: PostHog, reqData: String | null, discount: string | ''): Promise<void> {
  return new Promise((resolve, reject) => {
    signInWithPopup(auth, provider).then(async (result: any) => {
      // The signed-in user info.
      const user = result.user;
      const { email, uid } = user;
      const full_name = user.displayName;
      // check if the user already exists in the database
      const userRef = firebase.firestore().collection("users").doc(uid);

      // Identify user with posthog
      if (posthog) {
        posthog.identify(uid);
        posthog.people.set({
          $email: email,
          $name: full_name,
          $created: user.createdAt,
          $last_login: user.lastLoginAt,
          $user_id: uid,
        });
      }

      const doc = await userRef.get();
      if (doc.exists) {
        // Capture sign in event with posthog
        if (posthog) {
          try {
            posthog.capture('sign_in:google_popup', {
              $set: {
                last_sign_in: new Date(Number(user.metadata.lastLoginAt)).toISOString(),
              }
            });
          } catch (error) {
            console.error("Posthog error:", error);
          }
        }
        // user already exists, redirect to templates page
        handleUserRedirect(auth as any, navigate, posthog, false, discount);
      } else {
        // user does not exist, create user document, send welcome mail, and redirect to /welcome
        // call createUserDocument function from firebase functions

        // Capture sign up event with posthog
        if (posthog) {
          posthog.capture('sign_up:google_popup', {
            $set: {
              last_sign_in: new Date(Number(user.metadata.lastLoginAt)).toISOString(),
            }
          });
        }

        const createUserDocumentFunction = httpsCallable(
          functions,
          "create_user_document_py"
        );

        if (reqData == null) {
          reqData = "";
        }

        // Add the google_sign_up flag to set the welcome popup flag correctly
        await createUserDocumentFunction({ full_name, email, uid, newsletter_subscribed: newsletter_accepted, google_sign_up: true, reqData: reqData });

        // redirect to welcome page, set sign_up flag to true
        handleUserRedirect(auth as any, navigate, posthog, true);
      }
      resolve();
    }).catch((error) => {
      if (error.code === 'auth/popup-closed-by-user') {
        console.log('Sign-in popup was closed by the user.');
        // You can add any specific handling for this case here
        // For example, you might want to show a message to the user
        if (posthog) {
          posthog.capture('sign_in:google_popup_closed', {
            error: error.message
          });
        }
      } else {
        // Handle other errors
        console.error("Error during Google sign-in:", error);
        if (posthog) {
          posthog.capture('sign_in:google_error', {
            error: error.message
          });
        }
      }
      reject(error);
    });
  });
};

export async function userDocExists(uid: string) {
  const doc = await firebase
    .firestore()
    .collection("users")
    .doc(uid)
    .get();
  if (doc.exists) {
    return true;
  } else {
    return false;
  }
};

// If the user is logged in and verified, redirect them to the templates page
// If a user is logged in and not verified, redirect them to the verify email page
export async function checkUserState(auth: Auth, navigate: (arg0: string) => void, posthog:PostHog) {
  if (auth.currentUser?.uid && auth.currentUser.emailVerified) {
    // Check if the user doc exists, if it does, redirect them to the templates page
    // If it doesn't we don't want to redirect them here, since if they used another provider such as google/apple
    // The user will be logged in and verified before their user doc is created.
    // Thus we want to wait until the user doc is created and rely on the redirect in the utils/loginUtils.ts function
    if (await userDocExists(auth.currentUser.uid)) {
      handleUserRedirect(auth, navigate, posthog);
    }
  } else if (auth.currentUser?.uid && !auth.currentUser.emailVerified) {
    navigate("/verify-email");
  }
};

export async function handleUserRedirect(auth: Auth, navigate: (arg0: string) => void, posthog: PostHog, sign_up: boolean = false, discount: string = '') {
  const userDoc = await firebase.firestore().collection("users").doc(auth.currentUser?.uid).get();

  let navigateUrl = "/home";
  let welcomeUrl = "/welcome";
  let upgradeUrl = "/upgrade";

  // Get the firebase function to updateUserData 
  const updateUserDataFunction = httpsCallable(functions, "update_user_data_py");

  // If discount is not empty, redirect to upgrade page with discount
  if (discount !== '') {
    navigate(`${upgradeUrl}?discount=${discount}`);
    return;
  }

  // Handle Google sign up users, to not cause a race condition with the user doc creation
  // If a user has signed up with google, dont check the doc, just redirect to the welcome_page
  if (sign_up && auth.currentUser?.providerData[0]?.providerId === "google.com") {
    handleSignUpTracking(posthog);
    navigate(welcomeUrl);
    window.location.reload();
  } else if (userDoc.data()?.welcome_popup_seen) {
    navigate(navigateUrl);
  } else if (userDoc.data()?.welcome_popup_seen === false) {
    // Update the user document and set the flag "welcome_popup_seen" to true
    await updateUserDataFunction({
      welcome_popup_seen: true,
    });
    handleSignUpTracking(posthog);
    navigate(welcomeUrl);
    window.location.reload();
  } else {
    // If the user doc flag doesn't exist, redirect to templates
    // This is for old users that have not yet been migrated to the new user doc structure
    navigate(navigateUrl);
  }
};

/* Logout Function */
export async function logoutUser(auth: Auth, navigate: (arg0: string) => void) {
  Crisp.setTokenId(); // 1. Clear the token value
  Crisp.session.reset(); // 2. Unbind the current session
  auth.signOut().then(() => {
    navigate("/sign-in");
  });
}

const handleSignUpTracking = (posthog: PostHog) => {
  // Check if the tracking has already been done
  const hasRun = sessionStorage.getItem("leadTracking");

  // If the page is not fully loaded, wait for it to load
  if (document.readyState !== 'complete') {
      window.addEventListener('load', () => {
          handleSignUpTracking(posthog);
      });
      return;
  }

  if (!hasRun) { // Test if cookie lowers our true conversion.

      // Facebook Pixel Lead event tracking
      if (typeof window !== 'undefined' && (window as any).fbq) {
          (window as any).fbq('track', 'Lead');
      }

      // Google Enhanced Conversion Tracking Code
      // Needs to fire before the actual event code, otherwise the attribute is not set
      // ref: https://support.google.com/google-ads/answer/13262500
      if (typeof window !== 'undefined' && (window as any).dataLayer) {
          (window as any).dataLayer.push({
              'email_address': auth.currentUser?.email,
          });
      }

      // Google Tag Manager code for conversion tracking
      if (typeof window !== 'undefined' && (window as any).dataLayer) {
          (window as any).dataLayer.push({
              'event': 'SignUp',
          });
      }

      // Bing UET tag for conversion tracking
      if (typeof window !== 'undefined' && (window as any).uetq) {
          (window as any).uetq.push(
              'event', 'SignUp', {
          });
      }

      // Posthog event sign_up:completed
      let allCookiesAccepted = false;                    
      // Add this code to print the cookie
      const iubCookie = document.cookie
          .split('; ')
          .find(row => row.startsWith('_iub_cs-91682523='));
      if (iubCookie) {
          // Extract the value part after the '='
          const cookieValue = iubCookie.split('=')[1];
          // Decode the URL-encoded string and parse the JSON
          const decodedCookie = JSON.parse(decodeURIComponent(cookieValue));
          
          // Check if purpose exists and all its keys are true
          if (decodedCookie.purposes) {
              const allPurposesAccepted = Object.values(decodedCookie.purposes).every(value => value === true);
              if (allPurposesAccepted) {
                  allCookiesAccepted = true
              }
          }
      }

      posthog.capture('sign_up:handleSignUpTracking', {
          cookies_accepted: allCookiesAccepted
      });
      // Set a cookie to prevent tracking from firing multiple times
      sessionStorage.setItem("leadTracking", "true");
  }
};