import LayoutInSide from "../../layouts/LayoutInSide";
import { useLocation } from "react-router-dom";
import { useRef, useState, useEffect, useCallback } from "react";
import firebase from "../../config/fbConfig";
import { toast } from "react-toastify";
import SpinningLoader from "../../components/loader/spinning_loader";
import Loader from "../../components/loader/loader";
import templates from "../../data/templates.json";
import { httpsCallable } from "firebase/functions";
import {
  firestore,
  functions,
  doc,
  getDoc,
  onSnapshot,
  auth,
  gemini_model
} from "../../config/fbConfig";
import { decrementCreations, getAllUserData, setStopPrompt, UserData } from "../../utils/userUtils";
import { usePostHog } from 'posthog-js/react';
import Cookies from 'js-cookie'

// Modular Fireabse SDK imports
import { collection, setDoc, updateDoc, getDocs, query, where } from 'firebase/firestore';
import { useNavigate } from "react-router-dom";
import { DeleteIcon } from "../../assets/Icons";
import { useShareableData } from "../../providers/ShareableDataProvider";
import { DropdownSelect } from "../../components/inputs/DropdownSelect";

// Shadcn Card
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card"

// Shadcn Input
import { Input } from "../../components/ui/input"
import { Textarea } from "../../components/ui/textarea"

// Upgrade Modal
import UpgradeModal from "../../layouts/Modal/UpgradeModal";

// New InputCard component
import { InputCard } from "../../components/cards/InputCard";

// react-hook-form
import { useForm } from "react-hook-form";

// UI Button
import { Button } from "../../components/ui/button";
import { ArrowLeft } from "lucide-react";

// DocumentEditor component
import { DocumentEditor } from '../../components/editor/DocumentEditor';
import { DocumentEditorRef } from '../../components/editor/DocumentEditor';

// Firebase Function Reference
const resettemplate = httpsCallable(functions, "resettemplate_py");
const sendSlackMessage = httpsCallable(functions, "send_slack_alert");

const TemplateDetailPage = () => {

  // Constants
  const max_input_length = 2000;
  const languages = [
    { value: 'German', label: 'Deutsch' },
    { value: 'English', label: 'English' },
    { value: 'French', label: 'Französisch' },
    { value: 'Spanish', label: 'Spanisch' },
    { value: 'Italian', label: 'Italienisch' },
    { value: 'Portuguese', label: 'Portugisisch' },
    { value: 'Dutch', label: 'Niederländisch' },
    { value: 'Swedish', label: 'Schwedisch' },
    { value: 'Danish', label: 'Dänisch' },
    { value: 'Norwegian', label: 'Norwegisch' },
    { value: 'Finnish', label: 'Finnisch' },
    { value: 'Polish', label: 'Polnisch' },
    { value: 'Russian', label: 'Russisch' },
    { value: 'Turkish', label: 'Türkisch' },
  ];

  // State Variables
  const [generatedText, setGeneratedText] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userData, setUserData] = useState<UserData>({ "userData": "test", "productData": "test" });
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [loading, setLoading] = useState(false);
  const [cancel, setCancel] = useState(false);
  const cancelRef = useRef(false);
  const [stopping] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(true);
  const [templateEditable, setTemplateEditable] = useState(false);
  const [allowEdit, setAllowEdit] = useState(false);
  const [textLength, setTextLength] = useState(0);
  const [error, setError] = useState("");
  const [language, setLanguage] = useState(null || String)
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [activeTab, setActiveTab] = useState<'input' | 'output'>('input');
  const [showBottomNav, setShowBottomNav] = useState(false);
  const editorRef = useRef<DocumentEditorRef>(null);
  const [answerLength, setAnswerLength] = useState('Normal');
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);

  // Providers
  const posthog = usePostHog();
  const navigate = useNavigate();
  const [personas, setPersonas] = useState([{ "id": "", "name": "", "personatext": "" }])

  const [persona, setPersona] = useState('');
  const templateDetail = useLocation();
  const { showOnBoarding, completeOnBoardingStep } = useShareableData();

  // References
  const selectedTemplate = templateDetail.state;

  // Unique generation id, used to track the generation
  // its a combination of the user id and the current timestamp
  // its assigned a value every time a generation is started
  let generation_id = "";

  // New state for expanded card index
  const [expandedCardIndex, setExpandedCardIndex] = useState(0);

  // Initialize formValues with react-hook-form
  const { register, handleSubmit, watch, setValue } = useForm();

  // Functions & Event Handlers

  const getUserTemplates = async () => {
    const data: any[] = [];

    const writeDataDocRef = collection(firestore, "users", auth.currentUser!.uid, "personas");

    onSnapshot(writeDataDocRef, (snapshot: any) => {
      let personasData = snapshot.docs.map((doc: any) => ({ id: doc.id, ...doc.data() }));
      personasData.sort((a: any, b: any) => (a.created_at > b.created_at) ? 1 : -1);
      const standardPersona = personasData.find((persona: any) => persona.standard === true);
      const defaultPersona = standardPersona || { "id": "", "name": "Kein Tonfall", "personatext": "" };

      // Add black star icon to standard persona name
      const defaultPersonaWithStar = {
        ...defaultPersona,
        name: `★ ${defaultPersona.name}`
      };

      setPersonas([defaultPersonaWithStar, ...personasData.filter((persona: any) => persona.id !== defaultPersona.id)]);

      // Set the initial value for the persona dropdown
      if (persona) {
        setPersona(defaultPersonaWithStar);
      }
    });

    await firebase
      .firestore()
      .collection("users")
      .doc(auth.currentUser!.uid)
      .collection("templates")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          data.push(doc.data().template_data);
        });
      })
      .catch(err => {
        console.log(err.message);
      });

    return data;
  };

  const loadUserTemplates = async () => {
    setLoadingTemplates(true);
    const userTemplates = await getUserTemplates();
    // Prepending the user templates to the templates from JSON
    const templates_out = [...userTemplates, ...templates];
    setLoadingTemplates(false);

    // Check if template type is "Persönliche Vorlage"
    if (selectedTemplate.type === "Persönliche Vorlagen") {
      setTemplateEditable(true);
      setAllowEdit(true);
    }
    return templates_out;
  }

  const loadOutputField = async () => {
    if (auth.currentUser) {
      const userDocRef = doc(collection(firestore, "users"), auth.currentUser!.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        // Set up real-time listener
        onSnapshot(userDocRef, (userDocSnap) => {
          if (userDocSnap.exists()) {
            setLoading(false);
            const userDocData = userDocSnap.data();
            if (Cookies.get('useFallback') !== 'true') {
              setGeneratedText(userDocData.currentresponse);
            }
          } else {
            console.error("User document not found");
          }
        });
      } else {
        console.error("User document not found");
      }
    } else {
      console.error("User not authenticated");
    }
  };

  const renderInputs = () => {
    if (loadingTemplates) {
      return <SpinningLoader />
    }
    const placeholders = selectedTemplate.placeholders;

    return placeholders.map((placeholder: any, index: number) => {
      const { label, type, description } = placeholder;
      const isExpanded = index === expandedCardIndex;
      const isLast = index === placeholders.length - 1;
      const fieldName = `input_${index}`;
      const value = watch(fieldName) || '';
      const hasContent = value.trim() !== '';

      return (
        <InputCard
          key={index}
          title={label}
          description={description}
          isExpanded={isExpanded}
          onToggle={() => setExpandedCardIndex(isExpanded ? -1 : index)}
          hasContent={hasContent}
          onNext={!isLast ? () => {
            setExpandedCardIndex(index + 1);
          } : undefined}
          hideButton={isLast}
        >
          {type === "textarea" && (
            <>
              <div className="relative">
                <p className="text-[13px] text-gray-1000 font-medium font-poppins mb-1">
                  <span>{textLength}</span>/{max_input_length}
                </p>
                <Textarea
                  className="cflex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 focus:border-0 focus:ring-purple-500"
                  maxLength={Number.MAX_SAFE_INTEGER}
                  style={{ height: '200px', resize: 'vertical', paddingRight: '2.5rem' }}
                  placeholder="Text"
                  // Use react-hook-form's register
                  {...register(fieldName, {
                    onChange: (e) => onChangeHandler(e),
                  })}
                />
                {value && (
                  <button
                    className="absolute top-[1.75rem] right-2"
                    onClick={(e) => {
                      e.preventDefault();
                      onClearHandler(index);
                    }}
                  >
                    <DeleteIcon />
                  </button>
                )}
              </div>
            </>
          )}

          {type === "input" && (
            <>
              <div className="relative">
                <Input
                  maxLength={Number.MAX_SAFE_INTEGER}
                  placeholder="Text"
                  style={{ paddingRight: '1.5rem' }}
                  // Use react-hook-form's register
                  {...register(fieldName, {
                    onChange: (e) => onChangeHandler(e),
                  })}
                  className="focus:border-0 focus:ring-purple-500"
                />
                {value && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      onClearHandler(index);
                    }}
                    className="absolute top-[0.88rem] right-[0.625rem]"
                  >
                    <DeleteIcon />
                  </button>
                )}
              </div>
            </>
          )}
          <p className={`text-red-500 mt-5 ${error ? 'visible' : 'hidden'}`}>{error}</p>
        </InputCard>
      );
    });
  };

  async function getOpenAIResponse(prompt: string, systemPrompt: string) {
    // Return early if stopping is in progress
    if (stopping) {
      return Promise.resolve({
        success: false,
        message: "Stopping in progress",
      });
    }

    // Posthog Event for template_detail_page:start_generation
    generation_id = `${posthog.get_distinct_id()}_${Date.now()}`;
    posthog.capture('template_detail_page:start_generation', {
      generation_id,
      template: selectedTemplate.title,
      prompt,
      system_prompt: systemPrompt,
    });

    const getopenai = httpsCallable(functions, "getopenai_py", { timeout: 300000 });

    // Declare variables accessible in the catch block
    let lastResponse = ''; // Track the last response to calculate delta
    let hasReceivedInitialResponse = false;
    let timeoutId: NodeJS.Timeout | null = null;
    let unsubscribe: () => void = () => {};

    try {
      // Create a promise that rejects after 4 seconds if no initial response
      const timeoutPromise = new Promise<never>((_, reject) => {
        timeoutId = setTimeout(() => {
          if (!hasReceivedInitialResponse) {
            reject(new Error("Timeout: No response received after 4 seconds"));
          }
        }, 4000);
      });

      // Initialize document before starting generation
      await editorRef.current?.initializeDocument();

      // Clean up the previous content
      editorRef.current?.cleanupPreviousContent();

      unsubscribe = onSnapshot(
        doc(firestore, "users", auth.currentUser!.uid),
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();

            if (userData.currentlywriting) {
              const currentResponse = userData.currentresponse || '';

              // Mark that we've received initial response
              if (currentResponse && !hasReceivedInitialResponse) {
                hasReceivedInitialResponse = true;
                if (timeoutId) clearTimeout(timeoutId);
              }

              // Calculate new content
              if (currentResponse && currentResponse !== lastResponse) {
                const newContent = currentResponse.slice(lastResponse.length);
                if (newContent) {
                  // Handle newlines in the same way as Gemini
                  if (newContent.includes('\n')) {
                    // Split the chunk at newlines and preserve the newlines
                    const parts = newContent.split(/(\n)/);
                    // Insert each part separately
                    for (const part of parts) {
                      editorRef.current?.insertText(part);
                    }
                  } else {
                    editorRef.current?.insertText(newContent);
                  }
                  lastResponse = currentResponse;
                }
              }
            } else if (hasReceivedInitialResponse && !userData.currentlywriting) {
              // Finalize markdown when OpenAI generation is complete
              editorRef.current?.finalizeMarkdown();
              unsubscribe();
            }
          }
        },
        (error) => {
          console.error("Error in Firestore listener:", error);
          unsubscribe();
          if (timeoutId) clearTimeout(timeoutId);
        }
      );

      // Race between the OpenAI call and the timeout
      type OpenAIResponse = {
        data: {
          success: boolean;
          message: string;
          uid?: string;
        };
      };

      const result = (await Promise.race([
        getopenai({
          prompt,
          systemPrompt,
          template: selectedTemplate.title,
          generation_id,
        }),
        timeoutPromise,
      ])) as OpenAIResponse;

      const data = result.data;

      // Handle unsuccessful cases
      if (!data.success) {
        unsubscribe();
        if (timeoutId) clearTimeout(timeoutId);

        if (data.message === "Words exhausted") {
          setShowUpgradeModal(true);
        } else {
          // Fallback to Gemini - pass false to prevent double decrement
          await handleFallbackToGemini(prompt, systemPrompt, data.message, false);
        }
      }

      return data;
    } catch (error) {
      // This catches both timeout and other errors
      unsubscribe();
      if (timeoutId) clearTimeout(timeoutId);

      console.error("Error in OpenAI generation setup:", error);
      await handleFallbackToGemini(
        prompt,
        systemPrompt,
        error instanceof Error ? error.message : "Unknown error",
        false
      );
      return {
        success: false,
        message: error instanceof Error ? error.message : "Unknown error occurred",
      };
    }
  }

  // Helper function to handle Gemini fallback
  const handleFallbackToGemini = async (prompt: string, systemPrompt: string, errorMessage: string, shouldDecrement = true) => {
    // Set Session Cookie to continue to use Gemini
    Cookies.set('useFallback', 'true', { expires: 1 / 96 });

    // Send slack message if not in development
    if (window.location.hostname !== 'localhost' && window.location.hostname !== 'tst-robowriter.web.app') {
      sendSlackMessage({
        message: `Error in OpenAI Generation, falling back to Gemini. Error: ${errorMessage}`,
        generation_id: generation_id,
        url: window.location.href
      });
    }

    // Fallback to Gemini
    await getGeminiResponse(prompt, systemPrompt, false, shouldDecrement);
  };

  /**
   * This asynchronous function is used to get a response from the Gemini model.
   * It first resets the value of a textarea, then logs an event with Posthog.
   * After that, it calls the `generateContentStream` method from the `gemini_model` 
   * with a combination of `systemPrompt` and `prompt` as the input.
   * 
   * Once the content generation is done, it sets the loading state to false.
   * It then iterates over the result stream, appending each chunk of generated text 
   * to the current value of the textarea.
   * 
   * @param {string} prompt - The prompt to be used for generating content.
   * @param {string} systemPrompt - The system prompt to be used for generating content.
   * @async
   */
  async function getGeminiResponse(prompt: string, systemPrompt: string, due_to_timeout = false, shouldDecrement = true) {
    // Pull new user data
    // Assign it to a new variable, otherwise the userData state might not update in time to show the upgrade modal
    const newUserData = await getAllUserData(auth.currentUser!.uid);
    setUserData(newUserData);

    // check if the user has any words left in their plan
    // if not, show a toast and return
    if (!newUserData.userData!.unlimited && Number(newUserData.userData!.creations) <= 0 && Number(newUserData.userData!.bonus_creations) <= 0) {
      setShowUpgradeModal(true);
      return;
    }

    // Posthog Event for template_detail_page:fallback_gemini
    posthog.capture('template_detail_page:fallback_gemini', {
      generation_id: generation_id,
      template: selectedTemplate.title,
      prompt: prompt,
      system_prompt: systemPrompt,
    });

    // Initialize document before starting generation
    await editorRef.current?.initializeDocument();

    try {
      const request = [
        { text: systemPrompt },
        { text: prompt }
      ];

      const result = await gemini_model.generateContentStream(request);

      // Clean up the previous content
      editorRef.current?.cleanupPreviousContent();

      setLoading(false);
      cancelRef.current = false;
      setCancel(false);

      for await (const chunk of result.stream) {
        if (cancelRef.current) {
          break;
        }
        const chunkText = chunk.text();
        
        // Handle newlines in the same way as Gemini
        if (chunkText.includes('\n')) {
          // Split the chunk at newlines and preserve the newlines
          const parts = chunkText.split(/(\n)/);
          // Insert each part separately
          for (const part of parts) {
            editorRef.current?.insertText(part);
          }
        } else {
          editorRef.current?.insertText(chunkText);
        }
      }

      // Finalize markdown when Gemini generation is complete
      editorRef.current?.finalizeMarkdown();

      // Only decrement if shouldDecrement is true
      if (shouldDecrement && !due_to_timeout) {
        cancelRef.current = true;
        setCancel(true);
        await decrementCreations(auth.currentUser!.uid);
      }
    }
    catch (error) {

      // Check if error is a 429 rate limit error
      if (error instanceof Error && error.message.includes('429')) {
        // Remove the useFallback cookie if it exists
        Cookies.remove('useFallback');
      }

      toast.error("Fehler beim Generieren des Textes. Bitte versuchen Sie es erneut.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  }

  const handleLanguageChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const language = event.target.value;
    setLanguage(language)
    if (auth.currentUser) {
      const writeDataDocRef = doc(collection(firestore, "users", auth.currentUser!.uid, "writedata"), 'appdata');
      const docSnapshot = await getDoc(writeDataDocRef);
      if (docSnapshot.exists()) {
        await updateDoc(writeDataDocRef, { language });
      } else {
        await setDoc(writeDataDocRef, { language });
      }
    }
  }

  const onChangeHandler = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const allValues = watch();
    let totalValue = Object.values(allValues).join(' ');
    const totalWordsCount = totalValue
      .split(/\s+/)
      .filter((word) => word !== "").length;

    setTextLength(totalWordsCount);

    if (totalWordsCount > max_input_length) {
      setError(
        `Du benutzt zu viele Wörter in der Eingabe. Das Maximum wird um: ${totalWordsCount - max_input_length
        } Wörter überschritten.`
      );
      e.target.style.border = '1px solid red';
      posthog.capture('template_detail_page:character_limit_reached', {
        generation_id: generation_id,
        template: selectedTemplate.title,
        word_cnt: totalWordsCount,
      });
    } else {
      e.target.style.border = '';
      setError("");
    }
  }, [watch, generation_id, selectedTemplate.title, posthog]);

  const onClearHandler = useCallback((index: number) => {
    const fieldName = `input_${index}`;
    // Clear the value using react-hook-form's setValue
    setValue(fieldName, '');
  }, [setValue]);

  const onSubmitHandler = async (data: any) => {
    setLoading(true);
    setShowBottomNav(true);  // Show bottom navigation after first submission

    // Switch to the output tab
    setActiveTab('output');

    // Prepare inputValues from formValues
    const inputValues = selectedTemplate.replaceValues.map(
      (_: any, index: number) => data[`input_${index}`] || ''
    );

    // If we're currently generating (cancel is false), then stop
    if (!cancel) {
      if (Cookies.get('useFallback') === 'true') {
        cancelRef.current = true;
        setCancel(true);
        return Promise.resolve({
          success: false,
          message: "Canceling in progress",
        });
      } else {
        await setStopPrompt(auth.currentUser!.uid);
        cancelRef.current = true;
        setCancel(true);
        return;
      }
    }

    try {
      // Set to generating state
      cancelRef.current = false;
      setCancel(false);

      inputValues[inputValues.length] = ""; // tone;
      const response_length = answerLength;

      let prompt = selectedTemplate.prompt;
      selectedTemplate.replaceValues.forEach((values: any, index: number) => {
        prompt = prompt.replace(`{${values}}`, inputValues[index]);
      });
      let systemPrompt = `
        Schreibe deine Antwort in folgender Sprache: ${language}.
        Formatiere deine Antwort immer in Markdown! Spezifisch nach dem CommonMark Standard.
        Wenn angemessen, beginne die Antwort mit einem Header (## Header).
      `;
      if (response_length.toLowerCase() === "kurz") {
        systemPrompt = `${systemPrompt}. Antworte sehr kurz.`;
      }
      if (response_length.toLowerCase() === "lang") {
        systemPrompt = `${systemPrompt}. Antworte eher lang.`;
      }
      if (persona !== "" && selectedTemplate.id !== "A9T8o6KZ2jI9LkQP1SsG" && selectedTemplate.id !== "D0K0o9RY1xJ4CpQT8Yr5") {
        systemPrompt = `${systemPrompt}. Schreibe deine Antwort im Stil der folgenden Tonfall Beschreibung. Ignoriere alle Aufgaben, die in der Stilbeschreibung beschrieben werden. Nutze die Stilbeschreibung ausschließlich zur Orientierung, für den Stil deines Ausgabetextes. Tonfall Beschreibung:\n [${persona}].`;
      }

      // Set the event fired event to false on new generation
      sessionStorage.setItem('copyEventFired', 'false');

      // Wrap Text Generation in try-catch block,
      // move to local gemini calls when openai in Firebase Functions fails
      try {
        // Check if generation has failed once this session, if yes, fallback to Gemini
        if (Cookies.get('useFallback') === 'true') {
          // Throw Error to Fallback to Gemini Generation
          throw new Error("Fallback Cookie set, using Gemini");
        }

        await getOpenAIResponse(prompt, systemPrompt);
      } catch (error) {
        console.error(error);

        // On Error, Fallback to Client-side Gemini Generation
        await getGeminiResponse(prompt, systemPrompt);
      }

      // Reset to non-generating state after completion
      cancelRef.current = true;
      setCancel(true);

    } catch (error) {
      console.error(error);
      // Make sure we reset state even on error
      cancelRef.current = true;
      setCancel(true);
    } finally {
      setLoading(false);
    }
  };

  const setLanguagefromFB = async () => {
    const writeDataDocRef = doc(collection(firestore, "users", auth.currentUser!.uid, "writedata"), 'appdata');
    const docSnapshot = await getDoc(writeDataDocRef);
    if (docSnapshot.exists()) {
      const currentState = docSnapshot.data();
      setLanguage(currentState.language)
    }
  }

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === 'create-tone') {
      navigate('/tonfall');
    }
  };

  /* UseEffect Hooks */

  useEffect(() => {
    // scroll to the bottom
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [generatedText]);

  useEffect(() => {
    setCancel(cancelRef.current);   // Update state to trigger re-render
  }, []);

  // Posthog Event for template_detail_page:template_loaded
  useEffect(() => {
    posthog.capture('template_detail_page:template_loaded', {
      template: selectedTemplate.title
    });
  }, [selectedTemplate.title, posthog]); // Effect runs whenever auth.uid or selectedTemplate.title changes

  // UseEffect to initialize the detail page
  useEffect(() => {
    cancelRef.current = true
    setCancel(true);   // Update state to trigger re-render
    loadUserTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    resettemplate().then(() => {
      loadOutputField();
    });

    // Setup onSnapshot listener for cancel signal
    const docRef = firebase.firestore().collection("users").doc(auth.currentUser!.uid);
    docRef.onSnapshot((doc: any) => {
      const data = doc.data();
      if (data && data.currentlywriting !== undefined && (Cookies.get('useFallback') !== 'true')) {
        cancelRef.current = !data.currentlywriting;
        setCancel(!data.currentlywriting);   // Update state to trigger re-render
      }
    });

    setLanguagefromFB()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAnswerLengthChange = (value: string) => {
    setAnswerLength(value);
  };

  // Add this function near your other Firebase-related functions
  const getTemplateIdByName = async (templateName: string) => {
    const templatesRef = collection(firestore, "users", auth.currentUser!.uid, "templates");
    const q = query(templatesRef, where("template_data.title", "==", templateName));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].id;
    }
    return null;
  };

  // Add this useEffect to watch form changes
  useEffect(() => {
    // Subscribe to form changes
    const subscription = watch((value, { name, type }) => {
      const values = Object.values(value);
      const isFilled = values.length > 0 && values.every(val => val && String(val).trim() !== '');
      setAllFieldsFilled(isFilled);
    });
    
    // Cleanup subscription on unmount
    return () => subscription.unsubscribe();
  }, [watch]);

  if (loadingTemplates) {
    return <SpinningLoader />
  } else {
    return (
      <>
        {loading && <Loader />}
        <LayoutInSide>
          <form noValidate onSubmit={handleSubmit(onSubmitHandler)} className="h-full flex-1 flex flex-col">
            {/* Main content area */}
            <div className="flex-grow flex flex-col lg:flex-row overflow-hidden">
              {/* Input Column */}
              <div className={`w-full lg:w-1/3 flex-shrink-0 ${activeTab === 'input' ? 'block' : 'hidden lg:block'}`}>
                <div className="h-full overflow-y-auto custom-scrollbar mx-4 mt-4 pb-20 lg:pb-4">
                  {/* Header Card */}
                  <Card className="border-gray-200 shadow-none sticky top-0 bg-white">
                    {/* Header & Subtitle */}
                    <CardHeader className="flex items-left">
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <div className="lg:hidden mr-4">
                            <Button
                              variant="ghost"
                              onClick={() => navigate('/templates')}
                              className="p-0"
                            >
                              <ArrowLeft className="h-6 w-6" />
                            </Button>
                          </div>
                          <CardTitle>{selectedTemplate.title}</CardTitle>
                        </div>
                        <CardDescription className="mt-2">{selectedTemplate.description}</CardDescription>
                      </div>
                    </CardHeader>
                    <CardContent>
                      <ul className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <li className="col-span-1">
                          <DropdownSelect
                            options={languages}
                            value={language}
                            onChange={(value) => {
                              setLanguage(value);
                              handleLanguageChange({ target: { value } } as React.ChangeEvent<HTMLSelectElement>);
                            }}
                            placeholder="Deutsch"
                            className="min-h-[2.5rem] truncate"
                          />
                        </li>
                        <li className="col-span-1">
                          <DropdownSelect
                            options={[
                              ...personas.map(p => ({ 
                                value: p.personatext, 
                                label: p.name.length > 25 ? p.name.substring(0, 25) + '...' : p.name
                              })),
                              { value: 'create-tone', label: '+ Tonfall erstellen' }
                            ]}
                            value={persona}
                            onChange={(value) => {
                              if (value === 'create-tone') {
                                navigate('/tonfall');
                              } else if (value === 'none') {
                                setPersona('');
                                handleSelectChange({ target: { value: '' } } as React.ChangeEvent<HTMLSelectElement>);
                              } else {
                                setPersona(value);
                                handleSelectChange({ target: { value } } as React.ChangeEvent<HTMLSelectElement>);
                              }
                            }}
                            placeholder="Tonfall"
                            className="min-h-[2.5rem] truncate"
                          />
                        </li>
                        <li className={`col-span-${templateEditable ? '1' : '2'}`}>
                          <DropdownSelect
                            options={[
                              { value: 'Normal', label: 'Normal' },
                              { value: 'Kurz', label: 'Kurz' },
                              { value: 'Lang', label: 'Lang' }
                            ]}
                            value={answerLength}
                            onChange={handleAnswerLengthChange}
                            placeholder="Textlänge"
                            className="min-h-[2.5rem] truncate"
                          />
                        </li>
                        {/* Edit Template Buttons */}
                        {templateEditable && (
                          <li className="col-span-1">
                            <Button 
                              variant="outline" 
                              className="w-full text-center min-h-[2.5rem] whitespace-normal text-sm py-1"
                              onClick={async (e) => {
                                e.preventDefault(); // Prevent form submission
                                const templateId = await getTemplateIdByName(selectedTemplate.title);
                                if (templateId) {
                                  navigate(`/edit/${templateId}`);
                                } else {
                                  toast.error("Template nicht gefunden");
                                }
                              }}
                            >
                              Vorlage Bearbeiten
                            </Button>
                          </li>
                        )}
                      </ul>
                    </CardContent>
                  </Card>

                  {/* Divider */}
                  <div className="h-px w-full bg-gray-200 my-4"></div>

                  {/* Render Placeholders */}
                  {renderInputs()}

                  {/* Divider */}
                  <div className="h-px w-full bg-gray-200 my-4"></div>

                  {/* Start and Stop Buttons */}
                  <div className={`flex md:left-[13rem] justify-center mt-auto px-4 ${showBottomNav ? 'fixed bottom-12 left-0 right-0 lg:static pb-4' : 'fixed bottom-0 left-0 right-0 lg:static lg:mt-4 pb-4'}`}>
                    <Button
                      type="submit"
                      variant={cancel && allFieldsFilled ? "default" : "secondary"}
                      data-testid={cancel ? "start-button" : "stop-button"}
                      className="w-full lg:w-auto lg:min-w-[200px]"
                    >
                      {cancel ? "Start" : "Stop"}
                    </Button>
                  </div>

                </div>
              </div>

              {/* Output Column */}
              <div
                className={`w-full lg:w-2/3 flex flex-col flex-grow overflow-hidden ${activeTab === 'output' ? 'block' : 'hidden lg:block'
                  }`}
              >

                {/* Output Field Box */}
                <div className={`lg:pt-4 lg:flex-grow flex flex-col h-[calc(100vh-100px)] lg:h-full overflow-y-auto ${showBottomNav ? 'pb-16' : ''}`}>
                  <DocumentEditor
                    ref={editorRef}
                    userId={auth.currentUser!.uid}
                    initialContent={generatedText}
                    initialTitle={selectedTemplate.title}
                    templateId={selectedTemplate.id}
                  />
                </div>
              </div>
            </div>

            {/* Bottom Navigation for Mobile */}
            {showBottomNav && (
              <div className="fixed bottom-0 left-0 right-0 md:left-[13rem] bg-white border-t border-gray-200 flex items-center h-14 px-4 z-10 space-x-4 lg:hidden">
                <Button
                  variant="outline"
                  onClick={event => {
                    event.preventDefault();
                    setActiveTab('input');
                  }}
                  className={`flex-1 h-10 text-sm ${activeTab === 'input' ? 'border-b-2 border-primary' : ''}`}
                >
                  Input
                </Button>
                <Button
                  variant="outline"
                  onClick={event => {
                    event.preventDefault();
                    setActiveTab('output');
                  }}
                  className={`flex-1 h-10 text-sm ${activeTab === 'output' ? 'border-b-2 border-primary' : ''}`}
                >
                  Output
                </Button>
              </div>
            )}
          </form>
          <UpgradeModal isOpen={showUpgradeModal} onRequestClose={() => setShowUpgradeModal(false)} />
        </LayoutInSide>
      </>
    );
  };
};

export default TemplateDetailPage;
