import { useNavigate } from 'react-router-dom';
import { CloseIcon } from '../../assets/Icons';

type Props = {
    onClose: () => void;
};

const WelcomeContent = ({ onClose }: Props) => {
    const navigate = useNavigate();

    const handleStartClick = () => {
        navigate("/home");
    }

    return (
        <div className='flex'>
            <button
                id="closeModal"
                onClick={() => {
                    onClose();
                }}
                className="absolute right-5 md:right-4 top-2 md:top-4 mb-4 md:w-6 md:h-6 w-4 h-4"
            >
                <CloseIcon />
            </button>
            <div className='bg-onboarding-gradient w-4/12 md:w-4/12 p-4 md:p-[22px] flex flex-col items-start justify-between'>
                <img src="/assets/images/logo-white.svg" alt="icon" className='h-7 invert brightness-0' />
                <div className="flex flex-col justify-center mb-8">
                    <div className="text-center text-white">
                        <div className="flex items-center justify-center mb-2">
                            <img src="/assets/images/onboarding-illustration.png" alt="icon" className="w-20 md:w-40 h-20 md:h-40" />
                        </div>
                        <p className="text-sm md:text-xl mt-4 font-bold">Generiere noch mehr Texte</p>
                        <p className="text-sm mt-2 w-full mx-auto hidden md:flex">Einführung abschließen und mehr Texte erstellen</p>
                    </div>
                </div>
            </div>
            <div className="pt-9 sm:pt-12 md:pt-[58px] p-2 sm:p-2.5 md:pl-6 md:pr-4 md:pb-8 flex-1 w-4/12 md:w-8/12 items-end flex flex-col">
                <h1 className=" text-[14px] sm:text-base md:text-[23px] text-start w-full font-bold">Willkommen bei Robowriter!</h1>
                <p className="hidden md:flex text-gray-500 text-[13px] leading-5 my-2 text-start">
                    Willkommen bei dem deutschsprachigen KI-Textgenerator Robowriter – mit der kostenlosen Version kannst du jeden Tag 3 Texte generieren.
                </p>
                <p className="text-gray-400 text-xs md:hidden mt-2 font-semibold text-start">
                    Einführung abschließen und einmalig bis zu 15 zusätzliche Text- im Monat erhalten
                </p>
                <p className="font-semibold text-start text-[14px] hidden md:flex">
                    Schließe die Einführung ab und erhalte einmalig bis zu 15 zusätzliche Texterstellungen 🎁
                </p>
                <p className="text-gray-400 mt-2 text-xs md:hidden font-semibold leading-5">
                    Oder schließe unser Premium-Abo ab und: </p>
                <p className='font-semibold mr-[auto] text-start text-[14px] mb-0 hidden md:flex'>
                    Oder schließe unser Premium-Abo ab und erhalte:
                </p>
                <div className="text-sm mb-4 font-semibold w-full text-start">
                    <div className='flex items-center mt-2 md:mt-4'>
                        <img src={'/assets/images/welcome-ai.png'} alt="icon" className="h-5 md:h-8 me-2" />
                        <p className='hidden md:flex'>Immer das beste KI-Modell</p>
                        <p className='md:hidden text-xs'>Erhalte das beste KI-Modell</p>
                    </div>
                    <div className='flex items-center mt-1'>
                        <img src={'/assets/images/welcome-doc.png'} alt="icon" className="h-5 md:h-8 me-2" />
                        <p className='hidden md:flex'>Unlimitierte KI, um Schreibarbeit zu sparen</p>
                        <p className='md:hidden text-xs'> Spare Zeit durch mehr Worte </p>
                    </div>
                </div>
                <button
                    onClick={handleStartClick}
                    data-testid="welcome-start-button"
                    className="ml-auto w-fit btn-default mb-3 me-3 md:mb-0 mt-6 sm:mt-8 md:mt-8 h-8 md:h-10 md:me-2 text-center items-center text-xs md:text-sm flex rounded font-semibold text-secondary bg-transparent tracking-wider border-[1px] border-secondary transition-all duration-200 ease-in-out hover:bg-secondary hover:text-white"
                >
                    <span className="mx-6 transition-colors text-center w-full nowrap duration-200">Starten</span>
                </button>
            </div>
        </div>
    )
}

export default WelcomeContent